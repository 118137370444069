<template>

    <v-container
    class="transparent-card elevation-7 mb-5 mt-5"
    >
    <v-row>
        <v-col
        cols="12"
        sm="12"
        md="4"
        class="text-center"
        align-self="center"
        >
        <h1 class="display-4 mb-3" v-html="title">
            StEP <br />
            2020
        </h1>

        <h2 class="subheading font-weight-regular" v-html="subtitle">
            28. Okt - 30. Okt <br />
            jeweils live ab 12:30
        </h2>
        <v-btn
            v-for="button in mainButtons"

            :key="button.url"
            x-large
            :color="button.color"
            dark
            class="mt-6 mx-2 pa-8"
            :href="button.url"
            target="__blank"
        >
            <v-icon left dark large class="mr-5" v-if="button.icon" >{{button.icon}}</v-icon>
            {{button.text}}
        </v-btn>
        <!--<v-btn
            x-large
            color="red darken-1"
            dark
            class="mt-6 pa-8"
            v-if="stream"
            :href="stream"
            target="__blank"
        >
            <v-icon left dark large class="mr-5">mdi-play</v-icon>
            Start Stream
        </v-btn>
        <br />
        <v-btn
            x-large
            color="deep-purple darken-1"
            dark
            class="mt-6 pa-8"
            v-if="discord"
            :href="discord"
            target="__blank"
        >
            <v-icon left dark large class="mr-5">mdi-discord</v-icon>
            Join our Discord
        </v-btn>-->
        </v-col>
        <v-col cols="12" sm="12" md="7">
        <p v-html="render_lead_text"></p>
        </v-col>
    </v-row>
    </v-container>

</template>
<script>
import MarkdownIt from "markdown-it";

let md = new MarkdownIt({
  html: true,
  typographer:  true
});

export default {
    props: ['title', 'subtitle', 'leadText', 'mainButtons'],
    data: () => (
        {
        }
    ),
    computed: {  
        render_lead_text: function () {
            return md.render(this.leadText);
        },
    }
}
</script>