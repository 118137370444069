<template>
  <v-container class="transparent-card elevation-7 mb-5 mt-5">
    <h2 class="text-h2 ma-3">{{title}}</h2>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in items" :key="i">
        <v-expansion-panel-header> {{item.question}} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="item.answer"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
export default {
  props: ['items', 'title'],
  data: () => ({}),
};
</script>