import { InMemoryCache } from '@apollo/client/cache';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import { createProvider } from './vue-apollo'

Vue.config.productionTip = false

new Vue({
  vuetify,
  apolloProvider: createProvider({cache: new InMemoryCache({
    possibleTypes: {
      StreamFieldInterface: ['MainContent', 'Timetable', 'Button', 'Link', 'Alert', 'RawHTMLBlock', 'Panel', 'Row', 'Col', 'Card', 'MarkdownWrappedBlock', 'FAQ', 'RichTextBlock']
    }
  })}),
  render: h => h(App)
}).$mount('#app')
