<template>
<v-container>
  <v-row>
    <v-col v-for="(card, index) in cards.items" :key="index" cols="12" md="4">
      <v-card class="mx-auto flex d-flex flex-column" style="height:100%">
        <v-img
          class="white--text align-end d-none d-md-flex"
          style="flex: 0 0 auto"
          height="200px"
          contain
          :src="card.image.url"
        >
          <v-card-title style="word-break: break-word; background: #1976d2de;">{{ card.title }}</v-card-title>
        </v-img>
        <v-img
          class="white--text align-end d-flex d-md-none primary"
        >
          <v-card-title style="word-break: break-word">{{ card.title }}</v-card-title>
        </v-img>
        <v-card-subtitle class="pb-0">
          {{ card.subtitle }}
        </v-card-subtitle>
        <v-card-text class="text--primary grow" 
          ><div>{{ card.text }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            small
            color="primary"
            dark
            v-bind:href="link.url"
            :target="link.target"
            v-for="link in card.actions"
            :key="link.text"
          >
            {{ link.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
export default {
    props: ['cards']
}
</script>