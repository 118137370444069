<template>
  <v-app>
    <v-app-bar color="primary" dark app absolute>
      <div class="d-flex align-center">
        <a href="/">
          <v-img
            alt="StEP Uni Saarland"
            class="shrink mr-2"
            contain
            src="./assets/logo.png"
            transition="scale-transition"
            width="40"
          />
        </a>
      </div>
      <span>
        {{page.menuText}} <br />
        <small>{{page.menuSubtext}}</small>
      </span>
      <v-spacer></v-spacer>
      <v-btn
        v-for="link in filter_visible(page.links)"
        :key="link.url"
        class="d-none d-md-inline-flex"
        text
        :target="link.target"
        :href="link.url"
        >{{ link.text }}</v-btn
      >
    </v-app-bar>

    <v-main>
      <v-container fluid class="pa-0">
        <!-- Stream -->
        <v-container
          v-if="embedded_stream"
          class="elevation-24"
          fluid
          style="background-color: #181818"
        >
          <v-row class="text-center" justify="center">
            <v-col class="text-center" cols="12" md="9">
              <div class="video-container">
                <iframe
                  :src="stream_url"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <iframe
                style="border: 1px solid #2d2d2d; min-height: 500px"
                frameBorder="0"
                :src="livechat_url"
                width="100%"
                height="100%"
              ></iframe>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          fluid
        >
          <!-- Main Content -->
          <div v-for="(itm, index) in page.body" :key="index">
            <main-box v-if="itm.blockType == 'MainContent'" :title="itm.title" :subtitle="itm.subtitle" :leadText="itm.leadText" :mainButtons="itm.mainButtons"></main-box>
            <timetable v-if="itm.blockType == 'Timetable'" :days="itm.days" :title="itm.title" >
            </timetable>
            <v-alert v-if="itm.blockType == 'Alert'" v-bind="itm.props==null? {} : Object.fromEntries(itm.props.split(' ').map(x=>x.split('='))) ">{{itm.text}}</v-alert>
            <v-container v-if="itm.blockType=='Panel'"
              class="transparent-card elevation-7 mb-5 mt-5 pa-8">
              <div v-for="(itm, index) in itm.blocks" :key="index">
                <p v-if="itm.field=='text'" v-html="render_md(itm.rawValue)"></p>
                <v-row v-if="itm.field=='row'" v-bind="itm.props==null? {} : Object.fromEntries(itm.props.split(' ').map(x=>x.split('='))) ">
                  <v-col sm="12" md="4" v-for="(col, index) in itm.columns" :key="index" v-bind="col.props==null? {} : Object.fromEntries(col.props.split(' ').map(x=>x.split('='))) ">
                    <p v-html="render_md(col.content)"></p>
                  </v-col>
                </v-row>
              </div>
            </v-container>
            <cards :cards="itm" v-if="itm.field=='cards'"></cards>
            <div v-if="itm.field=='html'" v-html="itm.value">
            </div>
            <v-container v-if="itm.field=='markdown'" class="mb-5 mt-5 pa-0">
              <v-sheet color="white" elevation="7">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col lg="6" cols="12">
                    <div class="pa-5 user-content" v-html="render_md(itm.value)">
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-sheet>
            </v-container>
             <v-container v-if="itm.field=='richtext'" class="mb-5 mt-5 pa-0">
              <v-sheet color="white" elevation="7">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col lg="6" cols="12">
                    <div class="pa-5 user-content" v-html="itm.value">
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-sheet>
            </v-container>
            <faq v-if="itm.field=='faq'" :title="itm.title" :items="itm.faqItems"></faq>
          </div>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import gql from 'graphql-tag'
import MainBox from './MainBox.vue';
import Timetable from './Timetable.vue';
import MarkdownIt from "markdown-it";
import Cards from './Cards.vue';
import Faq from './Faq.vue';

let md = new MarkdownIt({
  html: true,
  typographer:  true
});

let pid = parseInt(document.querySelector("body").dataset.pid);

export default {
  components: { MainBox, Timetable, Cards, Faq },
  name: "App",

  data: () => ({
    embedded_stream: "",
    page: {
      title: "",
      menuText: "",
      menuSubtext: "",
      links: []
    }
  }),
  computed: {
    stream_url: function () {
      return "https://www.youtube-nocookie.com/embed/" + this.embedded_stream;
    },
    livechat_url: function () {
      return `https://www.youtube.com/live_chat?v=${this.embedded_stream}&embed_domain=${window.location.hostname}`;
    },
    visible_buttons: function() {
      return this.main_buttons.filter((b) => b.visible);
    }
  },
  apollo: {
    page: {
      query: gql`
      query QueryPage($pid: Int!) {
        page(id: $pid) {
          ... on HomePage {
            title
            menuText
            menuSubtext
            links {
              ...on LabelledLink {
                text, url, target
              }
            },
            body{
              blockType,
              field,
              ...on MainContent{
                title, subtitle, leadText,
                mainButtons{
                  ...on Button{text, url, color, icon}
                }
              }
              ...on Timetable{
                title,
                days {
                  ...on Day {
                    vodLink {...on LabelledLink{text, url, target}}, date, event
                  }
                }
              }
              ...on RawHTMLBlock{
                value
              }
              ...on Alert{text, props}
              ...on ListBlock {
                items {
                  ...on Card {
                    title, subtitle, text, image {
                      url
                    }, actions {...on LabelledLink {text, url, target}}
                  }
                }
              }
              ...on MarkdownWrappedBlock { value }
              ...on RichTextBlock { value }
              ...on FAQ {
                title,
                faqItems {
                  ...on FAQItem {
                    question, answer
                  }
                }
              }
            }
          }
        }
      }
      `,
      update: function(data) {
        document.title = data.page.title;
        return data.page;
      },
      pollInterval: 60000,
      variables: { pid: pid }
    },
    /* presentations: {
      query: gql`
            {
              presentations(sort:"datetime") {
                intern,
                datetime,
                title,
                description,
                Links {
                  ... on ComponentLinkLinks {
                    URL, Label
                  }
                },
                category,
                vod,
                Speakers
              }
            }
          `,
      manual: true,
      result ({ data, loading }) {
        if (!loading) {
          this.presentations = data.presentations;
          this.update_presentation_view();
        }
      },
    } */
  },
  methods: {
    render_md: function (md_text) {
        return md.render(md_text);
    },
    update_presentation_view() {
      
    },
    filter_visible(links) {
      return links.filter((x) => !x.Invisible);
    },
    adjust_colors() {
      for (const day of this.days) {
        for (const presentation of day.presentations) {
          if (this.current != null && presentation.id == this.current.id) {
            if(this.show_current_red) {
              presentation.state = "current";
            }
            return;
          }
          presentation.state = "done";
        }
      }
    },
  },
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.transparent-card {
  background-color: #fafafaa3 !important;
}

.user-content h4 {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 10px !important;
}

.user-content h5 {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 10px !important;
}

.user-content h6 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 10px !important;
}

.user-content h3 {
  font-size: 3rem !important;
  font-weight: 400;
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 10px !important;
}

.user-content h2 {
  font-size: 3.75rem !important;
  font-weight: 300;
  line-height: 3.75rem;
  letter-spacing: -0.0083333333em !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 15px !important;
}

.user-content h1 {
  font-size: 6rem !important;
  font-weight: 300;
  line-height: 6rem;
  letter-spacing: -0.015625em !important;
  font-family: "Roboto", sans-serif !important;
}

.user-content hr {
  border-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.87);
  overflow: visible;
  height: 0;
  margin-bottom: 20px !important;
}

.user-content blockquote {
  padding: 16px 0 16px 24px;
  font-size: 18px;
  font-weight: 300;
}

.user-content img.left{
  text-align: left;
}
.user-content img.right{
  text-align: right;
}
.user-content img.full-width{
  text-align: center;
  width: 100%;
  height: auto;
}

.user-content ul {
  margin-bottom: 0.5em;
}

.user-content ol {
  margin-bottom: 0.5em;
}
</style>
