<template>
     <v-container class="transparent-card elevation-7 mt-5 mb-5">
        <v-row justify="space-around">
            <v-col cols="12" md="1">
            <span class="display-3 mt-4 text-right d-none d-md-block ml-3">
                <span
                style="
                    text-orientation: sideways;
                    writing-mode: vertical-lr;
                "
                v-html="render_md(title)"
                >
                Zeitplan
                </span>
                <v-btn
                text
                style="margin-left: -6px"
                color="primary"
                v-if="calendar_link"
                :href="calendar_link"
                >
                <v-icon>mdi-sort-calendar-ascending</v-icon
                ><small class="ml-1">iCal</small>
                </v-btn>
            </span>
            <span class="display-2 text-center d-block d-md-none">
                <span v-html="render_md(title)">Zeitplan</span>
                <v-btn text color="primary" v-if="calendar_link" :href="calendar_link">
                <v-icon>mdi-sort-calendar-ascending</v-icon
                ><small class="ml-1">iCal</small>
                </v-btn>
            </span>
            </v-col>
            <v-col cols="12" md="11">
            <v-row>
                <v-col
                cols="12"
                :md="12 / days.length > 8 ? 8 : 12 / days.length"
                v-for="day_obj in day_objs"
                :key="day_obj.date.toMillis()"
                >
                <v-card class="elevation-4">
                    <v-card-title class="white--text blue-grey">
                    <span class="title">{{
                        day_obj.date.toFormat("cccc dd.MM.")
                    }}</span>
                    <v-spacer />
                    <v-btn small class="white--text" v-if="day_obj.vodLink" text :target="day_obj.vodLink.target" :href="day_obj.vodLink.url">
                        <v-icon left small>
                                mdi-open-in-new
                        </v-icon>
                        {{
                        day_obj.vodLink.text
                    }}</v-btn>
                    </v-card-title>

                    <v-card-text>
                    <v-timeline
                        dense
                        v-for="presentation in day_obj.presentations"
                        :key="presentation.id"
                    >
                        <v-timeline-item
                        :color="render_timeline_color(presentation.state)"
                        small
                        right
                        >
                        <span :class="render_timeline_text_color(presentation.category)">
                            {{ render_time(presentation.datetime) }}
                            <b v-html="presentation.title"></b></span
                        >
                        <small class="ml-1">
                            {{ presentation.speakers }}
                        </small>
                        </v-timeline-item>
                        <v-timeline-item
                        hide-dot
                        right
                        style="margin-top: -17px"
                        >
                        <span v-html="presentation.description">
                        </span>
                        <div class="my-2">
                            <!-- I added display: flex; justify-content: left ml-0 pl-0 and text-truncate to make it not overlap the parent -->
                            <v-btn
                            style="display: flex; justify-content: left"
                            class="ml-0 pl-0 text-truncate"
                            target="__blank"
                            text
                            small
                            color="primary"
                            dark
                            v-bind:href="link.url"
                            v-for="link in presentation.links" :key="link.text"
                            >
                            <v-icon left class="ml-0">
                                mdi-open-in-new
                            </v-icon>
                            {{ link.text }}
                            </v-btn>
                        </div></v-timeline-item
                        >
                    </v-timeline>
                    </v-card-text>
                </v-card>
                </v-col>
            </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from 'graphql-tag'
import { DateTime } from "luxon";
import MarkdownIt from "markdown-it";

let md = new MarkdownIt({
  html: true,
  typographer:  true
});

export default {
    props: ['title', 'days'],
    data: () => (
        {
            calendar_link: "",
            presentations: []
        }
    ),
    computed: {
        day_objs: function() {
            if(!(this.days && this.presentations && this.days.length > 0 && this.presentations.length > 0))
                return [];
            
            let day_objs = this.days.map(d=>(
                {
                    date: DateTime.fromISO(d.date), 
                    vodLink:d.vodLink && d.vodLink.length > 0 ? d.vodLink[0] : null, 
                    presentations: this.presentations.filter(p => p.event == d.event && DateTime.fromISO(d.date).hasSame(DateTime.fromISO(p.datetime).startOf("day"), "day"))
                })
            );
            return day_objs;
        }
    },
    apollo: {
        presentations: {
            query: gql`
                {
                    presentations(order:"datetime") {
                        title, category, datetime, description, vod, speakers, event, links{
                            ...on LabelledLink {
                                text, url, target
                            }
                        }
                    }
                }
            `,
            pollInterval: 60000
        }
    },
    methods: {
        render_md: function (md_text) {
            return md.render(md_text);
        },
        render_timeline_text_color(state) {
            return [{
                "council": "blue--text",
                "lecture": "green--text",
                "external": "deep-purple--text"
            }[state]];
        },
        render_timeline_color(state) {
            if (state == "not_yet") return "blue";
            else if (state == "current") return "red";
            else return "grey";
        },
        render_time(s) {
            return DateTime.fromISO(s, {zone: 'utc'}).toLocaleString(DateTime.TIME_24_SIMPLE);
        },
    }
}
</script>
