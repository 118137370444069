import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

let vuetify = new Vuetify({
});

const simpleIcons = require('simple-icons');

for (const iconSlug in simpleIcons) {
    const icon = simpleIcons.Get(iconSlug);
    vuetify.framework.icons.values[`si-${icon.slug}`] = icon.path
}

export default vuetify;